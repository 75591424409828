import { Controller } from "@hotwired/stimulus"
import { Japanese } from "flatpickr/dist/l10n/ja";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

// Connects to data-controller="date-picker"
export default class extends Controller {
  connect() {
    this.datePicker();
  }

  datePicker = function () {
    if (document.getElementById("date_of_birth")) {
      if (document.getElementById("date_of_birth").value) {
        flatpickr(".birthday_datepicker", {
          wrap: true,
          maxDate: "today",
          locale: Japanese,
          disableMobile: "true",
          allowInvalidPreload: true,
          altInput: true,
          altFormat: "Y年m月d日",
        });
      } else {
        flatpickr(".birthday_datepicker", {
          wrap: true,
          maxDate: "today",
          locale: Japanese,
          defaultDate: "1999-04-01",
          disableMobile: "true",
          allowInvalidPreload: true,
          altInput: true,
          altFormat: "Y年m月d日",
        });
      }
    }

    /* Event Start Date */
    if (document.getElementById("event_start_date")) {
      if (document.getElementById("event_start_date").value) {
        flatpickr(".event_sDate", {
          wrap: true,
          minDate: "today",
          locale: Japanese,
          disableMobile: "true",
          allowInvalidPreload: true,
          altInput: true,
          altFormat: "Y年m月d日",
        });
      } else {
        flatpickr(".event_sDate", {
          wrap: true,
          minDate: "today",
          locale: Japanese,
          disableMobile: "true",
          allowInvalidPreload: true,
          altInput: true,
          altFormat: "Y年m月d日",
        });
      }
    }

    /* Event End Date */
    if (document.getElementById("event_end_date")) {
      if (document.getElementById("event_end_date").value) {
        flatpickr(".event_eDate", {
          wrap: true,
          minDate: "today",
          locale: Japanese,
          disableMobile: "true",
          allowInvalidPreload: true,
          altInput: true,
          altFormat: "Y年m月d日",
        });
      } else {
        flatpickr(".event_eDate", {
          wrap: true,
          minDate: "today",
          locale: Japanese,
          disableMobile: "true",
          allowInvalidPreload: true,
          altInput: true,
          altFormat: "Y年m月d日",
        });
      }
    }

    /* Fee Target Date */
    if (document.getElementById("fee_target_date")) {
      if (document.getElementById("fee_target_date").value) {
        flatpickr(".fee_target_date_datepicker", {
          wrap: true,
          locale: Japanese,
          disableMobile: "true",
          allowInvalidPreload: true,
          altInput: true,
          altFormat: "Y年m月d日",
          plugins: [
            new monthSelectPlugin({
              shorthand: true,
              dateFormat: "Y-m",
              altFormat: "Y年m月",
              theme: "light",
            }),
          ]
        });
      } else {
        flatpickr(".fee_target_date_datepicker", {
          wrap: true,
          locale: Japanese,
          defaultDate: "today",
          disableMobile: "true",
          allowInvalidPreload: true,
          altInput: true,
          altFormat: "Y年m月d日",
          plugins: [
            new monthSelectPlugin({
              shorthand: true,
              dateFormat: "Y-m",
              altFormat: "Y年m月",
              theme: "light",
            }),
          ]
        });
      }
    }
  }

  servSubscriberFeeTargetDateChange(event) {
    window.location = "/fee_confirmations/serv_subscriber_fee_confirmation?sel_id=" + $("#selected_id").val() + "&fee_target_date=" + event.target.value;
  }

  appUserFeeTargetDateChange(event) {
    window.location = "/fee_confirmations/app_user_fee_confirmation?sel_id=" + $("#selected_id").val() + "&fee_target_date=" + event.target.value;
  }
}
